/*
 * Theme vars - pass it to and from your designer
 *
 * Do not define defaults for font-family, because they will be set in the implementation.
 */

/* --------------------------------------------------------------------------- */
/* COLORS                                                                      */
/* --------------------------------------------------------------------------- */

:root {
  --color-general-black: #050e19;
  --color-general-white: #ffffff;
  --color-general-gray: #e8edf2;
  --color-general-light-gray: #f4f6f9;
  --color-general-blue-gray: #7791b3;

  --color-primary: #1b467f;
  --color-secondary: #d7272d;
  --color-tertiary: #1d2b42;

  --color-green: #73c532;

  /* --color-utilities-card-border: #eeeeee;
  --color-utilities-box-shadow: rgba(0, 0, 0, 0.2);
  --color-utilities-error-message: #df3d3d;

  --color-light-theme-primary: #468bd8;
  --color-light-theme-primary-hover: #4d92e1;
  --color-light-theme-primary-pressed: #357fd4;
  --color-light-theme-accent: #932f6d;
  --color-light-theme-gradient-start: #4fc3f7;
  --color-light-theme-gradient-end: #3939ab;

  --color-dark-theme-primary: #212f53;
  --color-dark-theme-light-primary: #2d3e6a;
  --color-dark-theme-accent: #5bc8ec; */
}

/* @media (prefers-color-scheme: light) {
  :root {
    --color-general-black: #22252a;
    --color-general-black-inverse: #ffffff;
    --color-general-white: #fdfdfd;
    --color-general-dark-gray: #6c757d;
    --color-general-medium-gray-1: #98a1a9;
    --color-general-medium-gray-2: #ced4da;
    --color-general-light-gray: #eaecef;
    --color-general-faint-white: #f8f9fa;

    --color-utilities-card-border: #eeeeee;
    --color-utilities-box-shadow: rgba(0, 0, 0, 0.2);
    --color-utilities-error-message: #df3d3d;

    --color-light-theme-primary: #468bd8;
    --color-light-theme-accent: #932f6d;
    --color-light-theme-gradient-start: #4fc3f7;
    --color-light-theme-gradient-end: #3939ab;

    --color-dark-theme-primary: #212f53;
    --color-dark-theme-light-primary: #2d3e6a;
    --color-dark-theme-accent: #5bc8ec;
  }
} */

/* TODO add true dark color scheme */
/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --color-general-black:              #FDFDFD;*/
/*    --color-general-black-inverse:      #FFFFFF;*/
/*    --color-general-white:              #22252A;*/
/*    --color-general-dark-gray:          #6C757D;*/
/*    --color-general-medium-gray-1:      #98A1A9;*/
/*    --color-general-medium-gray-2:      #CED4DA;*/
/*    --color-general-light-gray:         #EAECEF;*/
/*    --color-general-faint-white:        #F8F9FA;*/

/*    --color-utilities-card-border:      #333333;*/
/*    --color-utilities-box-shadow:       rgba(0,0,0,0.2);*/
/*    --color-utilities-error-message:    #DF3D3D;*/

/*    --color-light-theme-primary:        #468BD8;*/
/*    --color-light-theme-accent:         #932F6D;*/
/*    --color-light-theme-gradient-start: #4FC3F7;*/
/*    --color-light-theme-gradient-end:   #3939AB;*/

/*    --color-dark-theme-primary:         #212F53;*/
/*    --color-dark-theme-light-primary:   #2D3E6A;*/
/*    --color-dark-theme-accent:          #5BC8EC;*/
/*  }*/
/*}*/

/* --------------------------------------------------------------------------- */
/* TYPOGRAPHY                                                                  */
/* --------------------------------------------------------------------------- */

/*
 * COMMON
 */

:root {
  --font-family-base: "Gill Sans";
}

/*
 * VARIANTS
 * A - size in pixels
 * B - font weight
 *       100 - thin
 *       200 - light
 *       300 - semi-light
 *       400 - regular
 *       500 - medium
 *       600 - semi-bold
 *       700 - bold
 *       800 - extra-bold
 *       900 - black
 * C - text transform
 *       none
 *       UPPERCASE
 *       lowercase
 *       Capitalize
 */

:root {
  /*                                       A                    B           C  */

  --font-size-headline-1: calc((48 / 16) * 1rem);
  --letter-spacing-headline-1: calc((-0.5 / 16) * 1rem);
  --line-height-headline-1: calc((69 / 16) * 1rem);
  --font-weight-headline-1: 700;
  --text-transform-headline-1: none;

  --font-size-headline-2: calc((44 / 16) * 1rem);
  --letter-spacing-headline-2: calc((0 / 16) * 1rem);
  --line-height-headline-2: calc((44 / 16) * 1rem);
  --font-weight-headline-2: 700;
  --text-transform-headline-2: none;

  --font-size-headline-3: calc((36 / 16) * 1rem);
  --letter-spacing-headline-3: calc((0.25 / 16) * 1rem);
  --line-height-headline-3: calc((46 / 16) * 1rem);
  --font-weight-headline-3: 700;
  --text-transform-headline-3: none;

  --font-size-headline-4: calc((24 / 16) * 1rem);
  --letter-spacing-headline-4: calc((0 / 16) * 1rem);
  --line-height-headline-4: calc((32 / 16) * 1rem);
  --font-weight-headline-4: 600;
  --text-transform-headline-4: none;

  --font-size-headline-5: calc((18 / 16) * 1rem);
  --letter-spacing-headline-5: calc((-0.5 / 16) * 1rem);
  --line-height-headline-5: calc((22 / 16) * 1rem);
  --font-weight-headline-5: 600;
  --text-transform-headline-5: none;

  --font-size-subtitle-1: calc((20 / 16) * 1rem);
  --letter-spacing-subtitle-1: calc((-0.5 / 16) * 1rem);
  --line-height-subtitle-1: calc((24 / 16) * 1rem);
  --font-weight-subtitle-1: 600;
  --text-transform-subtitle-1: none;

  --font-size-body-1: calc((16 / 16) * 1rem);
  --letter-spacing-body-1: calc((0.5 / 16) * 1rem);
  --line-height-body-1: calc((22 / 16) * 1rem);
  --font-weight-body-1: 400;
  --text-transform-body-1: none;

  --font-size-body-2: calc((14 / 16) * 1rem);
  --letter-spacing-body-2: calc((0.25 / 16) * 1rem);
  --line-height-body-2: calc((22 / 16) * 1rem);
  --font-weight-body-2: 400;
  --text-transform-body-2: none;

  /* --font-size-button: calc((14 / 16) * 1rem);
  --letter-spacing-button: calc((1.25 / 16) * 1rem);
  --line-height-button: calc((14 / 16) * 1rem);
  --font-weight-button: 600;
  --text-transform-button: uppercase; */
  --font-size-button: calc((16 / 16) * 1rem);
  --letter-spacing-button: unset;
  --line-height-button: calc((19 / 16) * 1rem);
  --font-weight-button: 600;
  --text-transform-button: uppercase;

  /* --font-size-caption: calc((12 / 16) * 1rem);
  --letter-spacing-caption: calc((0.4 / 16) * 1rem);
  --line-height-caption: calc((18 / 16) * 1rem);
  --font-weight-caption: 400;
  --text-transform-caption: none; */
  --font-size-caption: calc((14 / 16) * 1rem);
  --letter-spacing-caption: calc((0.4 / 16) * 1rem);
  --line-height-caption: calc((16 / 16) * 1rem);
  --font-weight-caption: 400;
  --text-transform-caption: none;
}
