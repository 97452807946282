@font-face {
  font-family: "Gill Sans Bold";
  src: url("assets/fonts/GillSans-Bold.woff2") format("woff2"),
    url("assets/fonts/GillSans-Bold.woff") format("woff"),
    url("assets/fonts/GillSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gill Sans";
  src: url("assets/fonts/GillSans.woff2") format("woff2"),
    url("assets/fonts/GillSans.woff") format("woff"),
    url("assets/fonts/GillSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Gill Sans", "sans-serif" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-dropdown {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
