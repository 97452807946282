.ant-modal-confirm .ant-modal-confirm-btns {
  float: none;
  text-align: center;
}

/* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist*/
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial";
  font-family: "Arial";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
  content: "Comic Sans";
  font-family: "Comic Sans";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
  content: "Courier New";
  font-family: "Courier New";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: "Georgia";
  font-family: "Georgia";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  content: "Helvetica";
  font-family: "Helvetica";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before {
  content: "Lucida";
  font-family: "Lucida";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman";
}

.ql-font-arial {
  font-family: "Arial";
}

.ql-font-comic-sans {
  font-family: "Comic Sans";
}

.ql-font-courier-new {
  font-family: "Courier New";
}

.ql-font-georgia {
  font-family: "Georgia";
}

.ql-font-helvetica {
  font-family: "Helvetica";
}

.ql-font-lucida {
  font-family: "Lucida";
}

.ql-font-times-new-roman {
  font-family: "Times New Roman";
}
/* alignment */
.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-right {
  text-align: right;
}
/* size */
.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-syntax {
  /* background-color: #23241f;
  color: #f8f8f2; */
  overflow: visible;
}

blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
