@import "~antd/dist/antd.less";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 14px;
}

@font-size-base: 16px;@primary-color: #1b467f;@layout-sider-background: #1d2b42;@layout-header-background: #1d2b42;@text-color: #1b467f;@label-color: #1b467f;@menu-dark-item-hover-bg: #1b467f;@input-color: #050e19;@input-border-color: #7791b3;@select-border-color: #7791b3;@select-item-selected-color: #050e19;@btn-default-border: #7791b3;@form-vertical-label-padding: 0;@breadcrumb-base-color: #7791b3;@breadcrumb-last-item-color: #d7272d;@breadcrumb-font-size: 14px;@breadcrumb-link-color: #7791b3;@breadcrumb-link-color-hover: #d7272d;